@import "./_global.scss";
@import "./_forms.scss";

// custom css
.main-search-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
    padding: 0 2.5rem 0 2.5rem;
    width: 100%;
    max-width: 55rem;

    @media screen and (max-width: $tablet-wide) {
        height: 350px;
        padding: 2rem 0 1.5rem 0;
        max-width: 45rem;
    }

    @media screen and (max-width: $phone-wide) {
        height: 550px;
        padding: 4rem 0 1.5rem 0;
    }
}

.main-search-heading {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 4.5rem;
    font-weight: 900;
    margin-bottom: 1rem;
    text-align: center;

    @media screen and (max-width: $tablet-wide) {
        font-size: 4rem;
    }

    @media screen and (max-width: $phone-wide) {
        font-size: 3.75rem;
        width: 90%;
    }
}

.main-search-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    width: 100%;

    @media screen and (max-width: $tablet-wide) {
        padding-bottom: 2rem;
    }

    @media screen and (max-width: $phone-wide) {
        flex-direction: column;
        padding-bottom: 1rem;
        width: 92%
    }
}

.main-search-form-input-field-container {
    display: flex;
    flex-direction: column;
    padding-right: 1rem;
    width: 20.813rem;

    @media screen and (max-width: $tablet-wide) {
        width: 18rem;
    }

    @media screen and (max-width: $phone-wide) {
        padding-right: 0;
        padding-bottom: 1rem;
        width: 100%;
    }
}

.main-search-form-input-field {
    background-color: #fff;
    border-radius: 4px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
}

.main-search-form-button-container {
    display: flex;
    padding-top: 1.05rem;

    @media screen and (max-width: $phone-wide) {
        justify-content: center;
        width: 100%;

        button {
            width: 100%;
        }
    }
}

.main-search-divider {
    display: flex;
    border-bottom: 1px solid $primary-dark-blue;
    height: 2px;
    margin-top: 1rem;
    width: 100%;

    @media screen and (max-width: $tablet-wide) {
        max-width: 43rem;
    }

    @media screen and (max-width: $phone-wide) {
        max-width: 20rem;
    }
}