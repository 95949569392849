@import "./_global.scss";

.main-feature-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    height: auto;
    width: 100%;

    @media screen and (max-width: $tablet-wide) {
        width: 85%;
    }

    @media screen and (max-width: $phone-wide) {
        flex-direction: column;
    }
}

.main-feature-reverse {
    @media screen and (max-width: $phone-wide) {
        flex-direction: column-reverse;
    }
}

.main-feature-container-mb {
    margin-bottom: 7rem;

    @media screen and (max-width: $phone-wide) {
        margin-bottom: 4.5rem;
    }
}

.main-feature-image {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
        display: block;
        width: 100%;
        height: 100%;
        max-height: 550px;
        max-width: 550px;
        box-shadow: 0 10px 25px 10px rgb(26 38 48 / 24%);
    }

    @media screen and (max-width: $phone-wide) {
        margin-bottom: 1.5rem;
        width: 90%;
    }
}

.main-feature-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 5%;
    padding-right: 5%;
    height: 100%;
    max-height: 550px;
    width: 100%;
    max-width: 550px;
}

.main-feature-text-block {
    line-height: 1.5rem;
}

.main-feature-join-link {
    color: $action-purple;
    font-size: 1.125rem;
    font-weight: 700;

    &:hover {
        cursor: pointer;
    }
}