@import "./_global.scss";

$footer-height: 340px;
$footer-height-phone: 260px;

$footer-logo-screen-max: 208px;
$footer-logo-tablet-max: 138px;

.footer-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $primary-dark-blue;
    min-height: $footer-height;
    padding-top: 5.5rem;
    padding-left: 2.5rem;
    padding-right: 3rem;
    width: 100%;
    
    @media screen and (max-width: $phone-wide) {
        min-height: $footer-height-phone;
        padding-top: 3.75rem;
        padding-left: $page-phone-padding-x;
        padding-right: $page-phone-padding-x;
    }
}

.footer-logo-container {
    display: flex;
    width: 100%;
    
    @media screen and (max-width: $phone-wide) {
        justify-content: center;
    }
}

.footer-logo {
    width: $footer-logo-screen-max;

    @media screen and (max-width: $phone-wide) {
        width: $footer-logo-tablet-max;
    }
}

.footer-divider {
    display: flex;
    border-bottom: 1px solid #707070;
    border-radius: 1px;
    margin-top: 2.75rem;
    width: 100%;
}

.footer-lower-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2.75rem;
    width: 100%;
    
    @media screen and (max-width: $phone-wide) {
        margin-top: 1.25rem;
        flex-direction: column;
    }
}

.footer-copyright-text {
    display: flex;
    flex-grow: 1;
    color: #a0a0a0;
    font-size: .75rem;

    a {
        color: #a0a0a0;
        text-decoration: underline;
    }
}

.footer-social-icon-container {
    display: flex;
    
    @media screen and (max-width: $phone-wide) {
        margin-top: 2rem;
    }
}

.footer-social-icon {
    svg {
        color: #ffffff;
        font-size: 1rem;
    }
}

.footer-social-icon-me {
    margin-right: 1rem;
    
    @media screen and (max-width: $phone-wide) {
        margin-right: 2rem;
    }
}