@import "./_global.scss";
@import "./forms.scss";

$header-logo-screen-max: 230px;
$header-logo-phone-max: 200px;

.header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #ffffff;
    height: $header-height;
    padding-left: 2.5rem;
    padding-right: 3rem;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 9999; 
    filter: drop-shadow(.25rem 0 .125rem rgb(175, 175, 175));
    
    @media screen and (max-width: $phone-wide) {
        height: $header-height-phone;
        padding-left: $page-phone-padding-x;
        padding-right: $page-phone-padding-x;
    }
}

.header-logo-container {
    display: flex;
    flex-grow: 1;

    @media screen and (max-width: $phone-wide) {
        align-items: center;
    }
}

.header-logo {
    max-width: $header-logo-screen-max;
    width: $header-logo-screen-max;

    @media screen and (max-width: $phone-wide) {
        max-width: $header-logo-phone-max;
    }
}

.header-job-post-button {
    background-color: $hire-action-color !important;
    font-weight: normal;
    height: 2.25rem;
    max-height: 2.25rem;
    width: 7.25rem;
    max-width: 7.25rem;
    text-decoration: none;

    &:hover {
        background-color: $hire-action-hover-color !important;
    }
}