@import "./_global.scss";

.main-testimonial-card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: .5rem;
    margin-right: 7.625rem;
    margin-bottom: 6rem;
    margin-left: 7.625rem;
    padding-right: 1.563rem;
    padding-left: 1.563rem;
    width: 100%;
    max-width: 1100px;

    @media screen and (max-width: $tablet-wide) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 6rem;
    }

    @media screen and (max-width: $phone-wide) {
        margin-bottom: 0;
    }
}

.main-testimonial-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: .75rem;
    box-shadow: 0 0 6px 3px rgb(0 0 0 / 16%);
    margin-right: .75rem;
    margin-bottom: 1.25rem;
    margin-left: .75rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    width: 33.375rem;

    @media screen and (max-width: $tablet-wide) {
        padding-top: 6rem;
        padding-right: 1.25rem;
        padding-bottom: 6rem;
        padding-left: 1.25rem;
        width: 75%;
    }

    @media screen and (max-width: $phone-wide) {
        padding-top: 4rem;
        padding-right: 1.25rem;
        padding-bottom: 4rem;
        padding-left: 1.25rem;
        width: 100%;
    }
}

.main-testimonial-card-text {
    display: block;
    color: rgba(26, 38, 48, 0.75);
    margin-right: auto;
    margin-left: auto;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    height: auto;
    min-height: 150px;
    width: 90%;
}

.main-testimonial-card-ref-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: 500px;

    @media screen and (max-width: $phone-wide) {
        flex-direction: column;
    }
}

.main-testimonial-card-ref-headshot {
    margin-right: 1.75rem;
    padding-right: 0;
    height: 6.25rem;
    width: 6.25rem;

    img {
        display: inline-block;
        border: 1px solid #707070;
        border-radius: 50px;
        max-width: 100%;
        vertical-align: middle;
    }

    @media screen and (max-width: $phone-wide) {
        margin-right: 0;
    }
}

.main-testimonial-card-ref-text {
    display: flex;
    flex-direction: column;
    font-size: .875rem;
    justify-content: flex-start;

    a {
        color: rgba(26, 38, 48, 0.75);
        text-decoration: underline;
    }

    span {
        font-size: 1.5rem;
        line-height: 1.875rem;
        font-weight: 700;
        margin-bottom: .313rem;
        max-height: 8rem;
        max-width: 12.5rem;
        width: 100%;
    }

    @media screen and (max-width: $phone-wide) {
        flex-direction: column;
        margin-top: 1rem;
        text-align: center;
    }
}