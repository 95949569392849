@import "./_global.scss";

// form variables
$input-field-height: 3.5rem;
$input-field-border-radius: .25rem;
$submit-button-border-radius: .5rem;

// button variables
$action-color: $action-blue;
$action-hover-color: $action-hover-blue;
$hire-action-color: #FF5C5C;
$hire-action-hover-color: #BF4545;
$danger-color: #FF5C5C;
$danger-hover-color: #BF4545;
$cancel-color: #EDEDED;
$cancel-hover-color: #BFBFBF;

input {
    border: 1px solid #0000001F;
}

input:not([type="checkbox"]),
textarea,
select {
    border: 1px solid #0000001F;
    border-radius: $input-field-border-radius;
    outline: none;
    height: $input-field-height;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    @media screen and (max-width: $phone-wide) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

input:focus,
textarea:focus,
select:focus {
    border-color: $primary-dark-blue;
}

input::placeholder {
    font-size: .875rem;
}

label {
    color: #575757;
    display: block;
    font-size: 0.75rem;
    margin-left: 1.5rem;
    margin-bottom: .25rem;

    @media screen and (max-width: $phone-wide) {
        margin-left: 1rem;
    }
}

.validation-error {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #3f3f3f;
    border-radius: .5rem;
    color: #ffffff;
    font-size: .875rem;
    font-weight: normal;
    height: 2.5rem;
    margin-top: .25rem;
    margin-bottom: .25rem;
    text-align: center;
    width: 96%;

    svg {
        color: $danger-color;
        font-size: 1.5rem;
    }

    @media screen and (max-width: $phone-wide) {
        min-height: 2.5rem;
        width: 85%;
    }
}

.form-submit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $action-color;
    border: 0rem;
    border-radius: $submit-button-border-radius;
    color: #FFFFFF;
    font-size: .875rem;
    font-weight: bold;
    height: $input-field-height;
    width: 8rem;
    text-transform: uppercase;

    &:hover {
        background-color: $action-hover-color;
        cursor: pointer;
    }
    &:disabled {
        background-color: $action-hover-color;
        color: #ededed;
        cursor: default;
    }
}

.form-required-asterisk {
    color: red;
}