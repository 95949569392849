@import "./_global.scss";

.main-action-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;

    h2 {
        max-width: 75%;
    }

    @media screen and (max-width: $phone-wide) {
        padding-left: 2rem;
        padding-right: 2rem;

        h2 {width: 92%}
    }

}

.main-action-title-divider {
    border-top: 1px solid $primary-dark-blue;
    height: 2px;
    width: 100%;
}

.main-action-title {
    display: block;
    color: $primary-dark-blue;
    font-size: 3rem; 
    font-weight: 700;
    margin: 0;
    padding-top: 7rem;
    padding-bottom: 1.5rem;
    text-align: center;

    @media screen and (max-width: $tablet-wide) {
        width: 80%;
    }

    @media screen and (max-width: $phone-wide) {
        padding-top: 4rem;
        font-size: 2.125rem;
        line-height: 2.5rem;
        width: 100%;
    }
}

.main-action-job-post-button {
    background-color: $action-purple !important;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.125rem;
    margin-top: 1rem;
    margin-bottom: 15rem;
    height: 3.5rem;
    max-height: 3.5rem;
    width: 15rem;
    max-width: 15rem;
    text-decoration: none;

    &:hover {
        background-color: $action-hover-purple !important;
    }

    @media screen and (max-width: $tablet-wide) {
        margin-bottom: 8rem;
    }
}