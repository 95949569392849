// Variables
$page-height: 100vh;
$page-width: 1100px;
$inner-container-width: 1100px;

$page-screen-padding-x: 2rem;
$page-tablet-padding-x: 2rem;
$page-phone-padding-x: 1.875rem;

$header-height: 87px;
$header-height-phone: 87px;

$tablet-wide: 880px;
$tablet-wide-plus-1: 881px;
$phone-wide: 575px;
$phone-wide-plus-1: 576px;

$primary-dark-blue: #1a2630;
$primary-light-blue: #0e65d4;
$primary-green: #4ec2aa;
$primary-red: #ee4040;
$gray-text: #454545;
$link-blue: #0e65d4;
$action-purple: #bb27ff;
$action-hover-purple: #670E90;
$action-blue: #0e65d4;
$action-hover-blue: #08499f;

$spacer: 1rem;

*, *:before, *:after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
}

body {
    background-color: #ffffff;
    color: $primary-dark-blue;
    font-family: 'Roboto', sans-serif;
    min-height: $page-height !important;
    margin: 0;
    padding: 0;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: $page-height;
}

h1 {
    color: $primary-dark-blue;
    font-size: 3rem; 
    margin: 0;
}

h2 {
    color: rgba(26, 38, 48, 0.54);
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.9rem;
    margin: 0;
}

h3 {
    color: $primary-dark-blue;
    font-size: 1.75rem; 
    margin: 0;

    @media screen and (max-width: $phone-wide) {
        font-size: 1.24rem;
    }
}

h4 {
    color: $primary-dark-blue; 
    font-size: 1.5rem; 
    line-height: 1.875rem;
    margin: .625rem 0 .625rem 0;
}

h5 {
    color: $gray-text; 
    font-size: 1.24rem; 
    font-weight: normal; 
    margin: 0 0 .5rem 0
}

p {
    line-height: ($spacer * 1.5);
    margin-top: 0; 
    margin-bottom: 1rem;
}

video {
    display: inline-block;
    vertical-align: baseline;
}

.container {
    margin: 0 auto; 
    padding-left: $page-screen-padding-x;
    padding-right: $page-screen-padding-x;
    width: 100%;
    max-width: $page-width;
    
    @media screen and (max-width: $phone-wide) {
        padding-left: $page-phone-padding-x;
        padding-right: $page-phone-padding-x;
    }
}

.container-wide {
    margin: 0 auto; 
    width: 100%;
}

/* Custom style tweakers */
.fw-bold {font-weight: bold;}
.h-100 {height: $page-height !important;}

/* Bootstrap-like spacing */
.mt-0 {margin-top: 0 !important;}
.mt-1 {margin-top: ($spacer * .25) !important;}
.mt-2 {margin-top: ($spacer * .5) !important;}
.mt-3 {margin-top: $spacer !important;}
.mt-4 {margin-top: ($spacer * 1.5) !important;}
.mt-5 {margin-top: ($spacer * 3) !important;}
.mt-auto {margin-top: auto !important;}

.mb-0 {margin-bottom: 0 !important;}
.mb-1 {margin-bottom: ($spacer * .25) !important;}
.mb-2 {margin-bottom: ($spacer * .5) !important;}
.mb-3 {margin-bottom: $spacer !important;}
.mb-4 {margin-bottom: ($spacer * 1.5) !important;}
.mb-5 {margin-bottom: ($spacer * 3) !important;}
.mb-auto {margin-bottom: auto !important;}

.ms-0 {margin-left: 0 !important;}
.ms-1 {margin-left: ($spacer * .25) !important;}
.ms-2 {margin-left: ($spacer * .5) !important;}
.ms-3 {margin-left: $spacer !important;}
.ms-4 {margin-left: ($spacer * 1.5) !important;}
.ms-5 {margin-left: ($spacer * 3) !important;}
.ms-auto {margin-left: auto !important;}

.me-0 {margin-right: 0 !important;}
.me-1 {margin-right: ($spacer * .25) !important;}
.me-2 {margin-right: ($spacer * .5) !important;}
.me-3 {margin-right: $spacer !important;}
.me-4 {margin-right: ($spacer * 1.5) !important;}
.me-5 {margin-right: ($spacer * 3) !important;}
.me-auto {margin-right: auto !important;}

.mx-0 {margin-left: 0 !important; margin-right: 0 !important;}
.mx-1 {margin-left:  ($spacer * .25) !important; margin-right: ($spacer * .25) !important;}
.mx-2 {margin-left: ($spacer * .5) !important; margin-right: ($spacer * .5) !important;}
.mx-3 {margin-left: $spacer !important; margin-right: $spacer !important;}
.mx-4 {margin-left: ($spacer * 1.5) !important; margin-right: ($spacer * 1.5) !important;}
.mx-5 {margin-left: ($spacer * 3) !important; margin-right: ($spacer * 3) !important;}
.mx-auto {margin-left: auto !important; margin-right: auto !important;}

.my-0 {margin-top: 0 !important; margin-bottom: 0 !important;}
.my-1 {margin-top:  ($spacer * .25) !important; margin-bottom: ($spacer * .25) !important;}
.my-2 {margin-top: ($spacer * .5) !important; margin-bottom: ($spacer * .5) !important;}
.my-3 {margin-top: $spacer !important; margin-bottom: $spacer !important;}
.my-4 {margin-top: ($spacer * 1.5) !important; margin-bottom: ($spacer * 1.5) !important;}
.my-5 {margin-top: ($spacer * 3) !important; margin-bottom: ($spacer * 3) !important;}
.my-auto {margin-top: auto !important; margin-bottom: auto !important;}

.pt-0 {padding-top: 0 !important;}
.pt-1 {padding-top: ($spacer * .25) !important;}
.pt-2 {padding-top: ($spacer * .5) !important;}
.pt-3 {padding-top: $spacer !important;}
.pt-4 {padding-top: ($spacer * 1.5) !important;}
.pt-5 {padding-top: ($spacer * 3) !important;}
.pt-auto {padding-top: auto !important;}

.pb-0 {padding-bottom: 0 !important;}
.pb-1 {padding-bottom: ($spacer * .25) !important;}
.pb-2 {padding-bottom: ($spacer * .5) !important;}
.pb-3 {padding-bottom: $spacer !important;}
.pb-4 {padding-bottom: ($spacer * 1.5) !important;}
.pb-5 {padding-bottom: ($spacer * 3) !important;}
.pb-auto {padding-bottom: auto !important;}

.ps-0 {padding-left: 0 !important;}
.ps-1 {padding-left: ($spacer * .25) !important;}
.ps-2 {padding-left: ($spacer * .5) !important;}
.ps-3 {padding-left: $spacer !important;}
.ps-4 {padding-left: ($spacer * 1.5) !important;}
.ps-5 {padding-left: ($spacer * 3) !important;}
.ps-auto {padding-left: auto !important;}

.pe-0 {padding-right: 0 !important;}
.pe-1 {padding-right: ($spacer * .25) !important;}
.pe-2 {padding-right: ($spacer * .5) !important;}
.pe-3 {padding-right: $spacer !important;}
.pe-4 {padding-right: ($spacer * 1.5) !important;}
.pe-5 {padding-right: ($spacer * 3) !important;}
.pe-auto {padding-right: auto !important;}

.px-0 {padding-left: 0 !important; padding-right: 0 !important;}
.px-1 {padding-left:  ($spacer * .25) !important; padding-right: ($spacer * .25) !important;}
.px-2 {padding-left: ($spacer * .5) !important; padding-right: ($spacer * .5) !important;}
.px-3 {padding-left: $spacer !important; padding-right: $spacer !important;}
.px-4 {padding-left: ($spacer * 1.5) !important; padding-right: ($spacer * 1.5) !important;}
.px-5 {padding-left: ($spacer * 3) !important; padding-right: ($spacer * 3) !important;}
.px-auto {padding-left: auto !important; padding-right: auto !important;}

.py-0 {padding-top: 0 !important; padding-bottom: 0 !important;}
.py-1 {padding-top:  ($spacer * .25) !important; padding-bottom: ($spacer * .25) !important;}
.py-2 {padding-top: ($spacer * .5) !important; padding-bottom: ($spacer * .5) !important;}
.py-3 {padding-top: $spacer !important; padding-bottom: $spacer !important;}
.py-4 {padding-top: ($spacer * 1.5) !important; padding-bottom: ($spacer * 1.5) !important;}
.py-5 {padding-top: ($spacer * 3) !important; padding-bottom: ($spacer * 3) !important;}
.py-auto {padding-top: auto !important; padding-bottom: auto !important;}

.red-line {border: 1px solid red;}

// Some styles common across all pages
.page-title {
    font-weight: normal;
}

.page-title-divider {   
    color: $primary-dark-blue;
    margin-top: 1rem;
    margin-bottom: 2rem;
    max-width: 8rem;

    @media screen and (max-width: $phone-wide) {
        margin-bottom: 1.5rem;
        max-width: 6rem;
    }
}

.page-lead {
    color: $gray-text;
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 1.75rem;

    @media screen and (max-width: $phone-wide) {
        font-size: 1.125rem;
    }
}

.page-text {
    color: $gray-text;
    line-height: 1.5rem;
    white-space: pre-wrap;
}

.page-text-link {
    color: $link-blue;
    text-decoration: none;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.call-to-action-button {
    display: flex;
    align-self: center;
    font-size: 1.125rem;
    font-weight: bold;
    margin-top: 1.25rem;
    text-decoration: none;
    height: 56px;
    width: 15rem;

    @media screen and (max-width: $phone-wide) {
        width: 100%;
    }
}