@import "./_global.scss";

.main-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.main-content-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: $page-width;

    @media screen and (max-width: $tablet-wide) {
        h1 {
            max-width: 85%;
        }
    }

    @media screen and (max-width: $phone-wide) {
        h1 {
            max-width: 100%;
        }
    }
}

.main-content-section-divider {
    height: 2rem;
}