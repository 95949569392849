@import "./_global.scss";

.main-why-lead-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 2.5rem;
    padding-right: 2.5rem;

    h2 {
        max-width: 75%;
    }

    @media screen and (max-width: $tablet-wide) {
        h2 {
            max-width: 100%;
        }
    }
    
    @media screen and (max-width: $phone-wide) {
        padding-left: 0;
        padding-right: 0;
        text-align: center;

        h2 {
            max-width: 85%;
        }
    }
}

.main-why-title {
    color: $primary-dark-blue;
    font-size: 3rem; 
    font-weight: 700;
    margin: 0;
    padding-bottom: 1.5rem;

    @media screen and (max-width: $phone-wide) {
        padding-top: 3rem;
        font-size: 2.125rem;
        line-height: 2.5rem;
    }
}

.main-why-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 1.25rem;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 3.75rem;

    @media screen and (max-width: $phone-wide) {
        padding-bottom: 1rem;
    }
    
}

.main-why-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 14rem;
    margin-right: 1.75rem;
    margin-bottom: 1.5rem;
    margin-left: 1.75rem;

    img {
        width: 4.25rem;
        height: 4.25rem;
        margin-bottom: 3rem;
    }

    @media screen and (max-width: $phone-wide) {
        align-items: center;
    }
}

.main-why-reason-title {
    display: block;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.875rem;
    margin-bottom: .5rem;
}

.main-why-reason-body {
    display: block;
    max-width: 15.25rem;
    min-height: 8.75rem;
    font-size: 1rem;
    line-height: 1.4rem;
    text-align: left;

    @media screen and (max-width: $phone-wide) {
        text-align: center;
    }
}